export function observeComponent(BEJSSDKObserver) {
    let observer = void 0;
    let listeners = [];
    let doc = window.document;
    let readySet = [];
    let MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

    function checkSelector(selector, fn, indexList) {
        let elements = doc.querySelectorAll(selector);
        for (let i = 0, len = elements.length; i < len; i++) {
            // -1 means all instances
            if (indexList !== -1 && !(i in indexList)) {
                continue;
            }
            let element = elements[i];
            for (let j = 0; j < readySet.length; j++) {
                if (readySet[j] === element.className || readySet[j] === element.id) {
                    return;
                }
            }
            if (element.className) {
                readySet.push(element.className);
            }
            if (element.id) {
                readySet.push(element.id);
            }

            if (!element.ready || MutationObserver == null) {
                element.ready = true;
                fn.call(element, element);
            }
        }
    }

    function checkListeners() {
        listeners.forEach(function (listener) {
            return checkSelector(listener.selector, listener.fn, listener.indexList);
        });
    }

    function removeListener(selector, fn) {
        let i = listeners.length;
        while (i--) {
            let listener = listeners[i];
            if (listener.selector === selector && listener.fn === fn) {
                listeners.splice(i, 1);
                if (!listeners.length && observer) {
                    observer.disconnect();
                    observer = null;
                }
            }
        }
    }

    /**
     * Fire event on first js selector
     * @param selector string to watch on
     * @param fn       callback function
     * @param index_list can be undefined which means only first one
     *                   or -1 which means all
     *                   or a list of allowable indexes
     */
    BEJSSDKObserver.jsElementReady = function (selector, fn, index_list) {
        if (index_list === undefined) {
            index_list = [];
            index_list.push(0);
        }

        if (MutationObserver != null) {
            if (!observer) {
                observer = new MutationObserver(checkListeners);
                observer.observe(doc.documentElement, {
                    childList: true,
                    subtree: true
                });
            }
            listeners.push({selector: selector, fn: fn, indexList: index_list});
        } else {
            // <= IE8
            if (!document.addEventListener) {
                document.addEventListener = document.attachEvent;
            }
            document.addEventListener("DOMContentLoaded", function (event) {
                let elements = doc.querySelectorAll(selector);
                for (let i = 0, len = elements.length; i < len; i++) {
                    // -1 means all instances
                    if (index_list !== -1 && !(i in index_list)) {
                        continue;
                    }
                    let element = elements[i];
                    element.ready = true;
                    fn.call(element, element);
                }
            });
        }

        checkSelector(selector, fn, index_list);
        return function () {
            return removeListener(selector, fn);
        };
    };

    return BEJSSDKObserver.jsElementReady;
}
