import {SDKUtils} from "./sdkutils";
import {observeComponent} from "./observer";
import {linkBlockGeneratorComponent} from "./link-block-generator";

window.jsElementReady = observeComponent(window.BEJSSDKObserver = window.BEJSSDKObserver || {});

linkBlockGeneratorComponent(window.BELinkBlockGenerator || {});

(function (BEJSSDK) {
    // Private Properties
    var environment = BEJSSDK.ENVIRONMENT_PRODUCTION;
    var _capsule_response = null;
    var config = null;
    var _original_url = null;
    var _normalized_url = null;
    var _get_capsule_api_url = null;
    var displayCapsuleUrl = null;
    var debugMode = false;
    var allowDirectApi = true;
    // lower case the request parameters
    var normalizeRequestParameters = false;

    // 0 = debug
    // 1 = info
    // 2 = warning
    // 3 = error
    // 4 = fatal
    var logLevel = BEJSSDK.LOG_LEVEL_WARNING;

    // XHR handler
    var xhr = null;
    var legacyIE = false;

    // a list of errors that is retained and spewed out in the footer primarily for debugging
    var errorMessages = [];

    // an array of [entry point, time]
    var profileHistory = [];
    BEJSSDK.LOG_LEVEL_DEBUG = 0;
    BEJSSDK.LOG_LEVEL_INFO = 1;
    BEJSSDK.LOG_LEVEL_WARNING = 2;
    BEJSSDK.LOG_LEVEL_ERROR = 3;
    BEJSSDK.LOG_LEVEL_FATAL = 4;

    BEJSSDK.capsule = null;

    BEJSSDK.startTime = 0;
    BEJSSDK.connectTime = 0;

    BEJSSDK.PRODUCT_NAME = "be_ixf";
    BEJSSDK.CLIENT_NAME = "js_sdk";
    BEJSSDK.CLIENT_VERSION = process.env.SDK_VERSION;
    BEJSSDK.API_VERSION = "1.0.0";

    // Public Properties
    BEJSSDK.ENVIRONMENT_CONFIG = "sdk.environment";
    BEJSSDK.CHARSET_CONFIG = "sdk.charset";
    BEJSSDK.API_ENDPOINT_CONFIG = "api.endpoint";
    BEJSSDK.ACCOUNT_ID_CONFIG = "sdk.account";
    BEJSSDK.CONNECT_TIMEOUT_CONFIG = "sdk.connectTimeout";
    BEJSSDK.SOCKET_TIMEOUT_CONFIG = "sdk.socketTimeout";
    BEJSSDK.CRAWLER_CONNECT_TIMEOUT_CONFIG = "sdk.crawlerConnectTimeout";
    BEJSSDK.CRAWLER_SOCKET_TIMEOUT_CONFIG = "sdk.crawlerSocketTimeout";
    BEJSSDK.LOG_LEVEL_CONFIG = "loglevel";

    BEJSSDK.WHITELIST_PARAMETER_LIST_CONFIG = "whitelist.parameter.list";
    BEJSSDK.FDAPI_PARAMETER_LIST_CONFIG = "forcedirectapi.parameter.list";
    BEJSSDK.REQUESTPARAMETERS_CASEINSENSITIVE_CONFIG = "requestparameters.caseinsensitive";

    BEJSSDK.CRAWLER_USER_AGENTS_CONFIG = "crawler.useragents";

    BEJSSDK.CANONICAL_PROTOCOL_CONFIG = "canonical.protocol";
    BEJSSDK.CANONICAL_HOST_CONFIG = "canonical.host";
    BEJSSDK.CANONICAL_PAGE_CONFIG = "canonical.page";

    // environment definitions
    BEJSSDK.ENVIRONMENT_PRODUCTION = "production";
    BEJSSDK.ENVIRONMENT_STAGING = "staging";
    BEJSSDK.ENVIRONMENT_TESTING = "testing";

    BEJSSDK.DEFAULT_CHARSET = "UTF-8";
    BEJSSDK.DEFAULT_DIRECT_API_ENDPOINT = "https://api.brightedge.com";
    BEJSSDK.DEFAULT_API_ENDPOINT = "https://ixfd-api.bc0a.com";
    BEJSSDK.DEFAULT_ACCOUNT_ID = "0";
    BEJSSDK.DEFAULT_CONNECT_TIMEOUT = "1000";
    BEJSSDK.DEFAULT_SOCKET_TIMEOUT = "1000";
    BEJSSDK.DEFAULT_CRAWLER_CONNECT_TIMEOUT = "2000";
    BEJSSDK.DEFAULT_CRAWLER_SOCKET_TIMEOUT = "2000";

    // a list of query string parameters that are kept separated by |
    BEJSSDK.DEFAULT_WHITELIST_PARAMETER_LIST = "";
    // a list of query string parameters that are kept separated by |
    BEJSSDK.DEFAULT_FD_PARAMETER_LIST = 'ixf-api|ixf';
    // a list of crawler user agents case insensitive regex, so separate by |
    BEJSSDK.DEFAULT_CRAWLER_USER_AGENTS = "google|bingbot|msnbot|slurp|duckduckbot|baiduspider|yandexbot|sogou|exabot|facebot|ia_archiver|brightedge";
    // request parameters default to case sensitive
    BEJSSDK.DEFAULT_REQUESTPARAMETERS_CASEINSENSITIVE = false;
    // Public Method
    BEJSSDK.construct = function (additionalConfig) {
        BEJSSDK.startTime = new Date().getTime();
        config = {
            [BEJSSDK.ENVIRONMENT_CONFIG]: BEJSSDK.ENVIRONMENT_PRODUCTION,
            [BEJSSDK.API_ENDPOINT_CONFIG]: BEJSSDK.DEFAULT_API_ENDPOINT,
            [BEJSSDK.CHARSET_CONFIG]: BEJSSDK.DEFAULT_CHARSET,
            [BEJSSDK.ACCOUNT_ID_CONFIG]: BEJSSDK.DEFAULT_ACCOUNT_ID,
            [BEJSSDK.CONNECT_TIMEOUT_CONFIG]: BEJSSDK.DEFAULT_CONNECT_TIMEOUT,
            [BEJSSDK.SOCKET_TIMEOUT_CONFIG]: BEJSSDK.DEFAULT_SOCKET_TIMEOUT,
            [BEJSSDK.CRAWLER_CONNECT_TIMEOUT_CONFIG]: BEJSSDK.DEFAULT_CRAWLER_CONNECT_TIMEOUT,
            [BEJSSDK.CRAWLER_SOCKET_TIMEOUT_CONFIG]: BEJSSDK.DEFAULT_CRAWLER_SOCKET_TIMEOUT,
            [BEJSSDK.CRAWLER_USER_AGENTS_CONFIG]: BEJSSDK.DEFAULT_CRAWLER_USER_AGENTS,
            [BEJSSDK.WHITELIST_PARAMETER_LIST_CONFIG]: BEJSSDK.DEFAULT_WHITELIST_PARAMETER_LIST,
            [BEJSSDK.FDAPI_PARAMETER_LIST_CONFIG]: BEJSSDK.DEFAULT_FD_PARAMETER_LIST,
            [BEJSSDK.LOG_LEVEL_CONFIG]: BEJSSDK.LOG_LEVEL_WARNING,
            [BEJSSDK.REQUESTPARAMETERS_CASEINSENSITIVE_CONFIG]: BEJSSDK.DEFAULT_REQUESTPARAMETERS_CASEINSENSITIVE,
        };

        config = SDKUtils.simpleAssign(config, additionalConfig);

        /**
         * Build SDK by Webpack.
         * This section can only be in this file.
         * if custom SDK, we check if Marvel is enabled.
         * if not custom SDK, we use config file and insert Marvel into SDK
         * Note: process.env is required for building SDK by Webpack.
         */
        if (process.env.CUSTOM) {
            if (process.env.MARVEL_ENABLED) {
                const {enableMarvel} = require("@brightedge/marvel");
                // get Marvel config
                let marvelConfig = {};
                if (process.env.MARVEL_CONFIG_CONSISTENCY_CUSTOM) {
                    marvelConfig = JSON.parse(process.env.MARVEL_CONFIG_CONSISTENCY_CUSTOM)
                } else {
                    if (process.env.MARVEL_CUSTOMER_ID) {
                        marvelConfig['data-customerid'] = process.env.MARVEL_CUSTOMER_ID;
                    }

                    if (process.env.MARVEL_TEST_MODE) {
                        marvelConfig["data-testmode"] = process.env.MARVEL_TEST_MODE;
                    }
                }

                enableMarvel(marvelConfig);
            }
        } else {
            const {enableMarvel} = require("@brightedge/marvel");
            let marvelValidAccounts = null;
            if (process.env.MARVEL_API_ACCOUNTS) {
                marvelValidAccounts = JSON.parse(process.env.MARVEL_API_ACCOUNTS);
            } else {
                marvelValidAccounts = require("./marvel-valid-accounts").marvelValidAccounts;
            }

            const accountId = config[BEJSSDK.ACCOUNT_ID_CONFIG];
            if (marvelValidAccounts.hasOwnProperty(accountId)) {
                enableMarvel(marvelValidAccounts[accountId]);
            }
        }

        // set up the right logging level first thing
        logLevel = parseInt(config[BEJSSDK.LOG_LEVEL_CONFIG]);
        _original_url = document.location.href;

        normalizeRequestParameters = config[BEJSSDK.REQUESTPARAMETERS_CASEINSENSITIVE_CONFIG];

        let parameter_dict = SDKUtils.getParameterDictionaryFromUrl(_original_url);
        if (parameter_dict['ixf-debug']) {
            debugMode = SDKUtils.getBooleanValue(parameter_dict['ixf-debug']);
        }

        if (parameter_dict['ixf-endpoint'] != null) {
            allowDirectApi = false;
            if (parameter_dict['ixf-endpoint'].endsWith('api.bc0a.com') || parameter_dict['ixf-endpoint'].endsWith('brightedge.com')) {
                config[BEJSSDK.API_ENDPOINT_CONFIG] = parameter_dict['ixf-endpoint'];
            }
        }

        // if debug put logging level in debug mode
        if (debugMode) {
            logLevel = BEJSSDK.LOG_LEVEL_DEBUG;
        }

        // force this message to be showing by default so we can debug more easily
        logDebug("[BEIXF] config: " + JSON.stringify(config));

        environment = config[BEJSSDK.ENVIRONMENT_CONFIG];
        logDebug("force direct api list parameter=" + config[BEJSSDK.FDAPI_PARAMETER_LIST_CONFIG]);
        var white_list_parameter = config[BEJSSDK.WHITELIST_PARAMETER_LIST_CONFIG];
        logDebug("white list parameter=" + white_list_parameter);
        var whitelist_parameter_list = white_list_parameter.split('|');

        // determine the correct timeout to use
        var connect_timeout = config[BEJSSDK.CONNECT_TIMEOUT_CONFIG];
        var user_agent_pattern = config[BEJSSDK.CRAWLER_USER_AGENTS_CONFIG];
        if (SDKUtils.userAgentMatchesRegex(navigator.userAgent, user_agent_pattern)) {
            connect_timeout = config[BEJSSDK.CRAWLER_CONNECT_TIMEOUT_CONFIG];
            logDebug("Detected browser using timeout=" + connect_timeout);
        }

        // #1 one construct the canonical URL
        _normalized_url = SDKUtils.normalizeUrl(_original_url, whitelist_parameter_list, normalizeRequestParameters);

        if (config[BEJSSDK.CANONICAL_PAGE_CONFIG]) {
            _normalized_url = config[BEJSSDK.CANONICAL_PAGE_CONFIG];
        } else if (config[BEJSSDK.CANONICAL_HOST_CONFIG]) {
            logDebug("Got in canonical host");
            _normalized_url = SDKUtils.overrideHostInURL(_normalized_url, config[BEJSSDK.CANONICAL_HOST_CONFIG]);
        }
        if (config[BEJSSDK.CANONICAL_PROTOCOL_CONFIG]) {
            _normalized_url = SDKUtils.overrideProtocolInURL(_normalized_url, config[BEJSSDK.CANONICAL_PROTOCOL_CONFIG]);
        }
        var page_hash = SDKUtils.getPageHash(_normalized_url);

        // force api key
        if (allowDirectApi) {
            let forceDirectApiListParameterList = config[BEJSSDK.FDAPI_PARAMETER_LIST_CONFIG].split('|');
            for (let key in parameter_dict) {
                if (forceDirectApiListParameterList.indexOf(key) !== -1) {
                    config[BEJSSDK.API_ENDPOINT_CONFIG] = 'https://api.brightedge.com';
                    logDebug("Using overridden api endpoint");
                    break;
                }
            }
        }

        var api_base = config[BEJSSDK.API_ENDPOINT_CONFIG];
        var account_id = config[BEJSSDK.ACCOUNT_ID_CONFIG];
        var request_path = '/api/ixf/' + BEJSSDK.API_VERSION + '/get_capsule/' + account_id + '/' + page_hash;
        _get_capsule_api_url = api_base + request_path + "?client=" + encodeURIComponent(BEJSSDK.CLIENT_NAME) +
            "&client_version=" + encodeURIComponent(BEJSSDK.CLIENT_VERSION) + "&orig_url=" + encodeURIComponent(_original_url) +
            "&base_url=" + encodeURIComponent(_normalized_url) + "&user_agent=" + encodeURIComponent(navigator.userAgent);
        logInfo("Page_hash normalized_url=" + _normalized_url + ", page_hash=" + page_hash + ", api_url=" + _get_capsule_api_url);

        displayCapsuleUrl = api_base + request_path;

        // XHR works in Chrome, Firefox, and IE 10 and above
        // IE9/IE8 gives SCRIPT5: Access is denied. see @https://www.leggetter.co.uk/2010/03/12/making-cross-domain-javascript-requests-using-xmlhttprequest-or-xdomainrequest.html
        var ieVersion = SDKUtils.getBrowserVersion();
        legacyIE = false;
        var isIE = false;

        if (ieVersion !== 0) {
            isIE = true;
            if (ieVersion < 10) {
                legacyIE = true;
            }
        }

        if (legacyIE) {
            var document_protocol = document.location.protocol.substring(0, document.location.protocol.length - 1);
            _get_capsule_api_url = SDKUtils.overrideProtocolInURL(_get_capsule_api_url, document_protocol);

            // @see https://developer.mozilla.org/en-US/docs/Web/API/XDomainRequest
            xhr = new window.XDomainRequest();
        } else {
            xhr = new XMLHttpRequest();
        }

        var async_xhr = true;

        if (async_xhr) {
            xhr.onload = BEJSSDK.xhrHandler;
            xhr.onerror = BEJSSDK.xhrErrorHandler;
        }
        xhr.open("GET", _get_capsule_api_url, async_xhr);
        // timeout is not supported on IE: SCRIPT5022: InvalidStateError
        // @see https://github.com/stephanebachelier/superapi/issues/5
        xhr.timeout = connect_timeout;

        xhr.send(null);
        if (!async_xhr) {
            BEJSSDK.processCapsule(xhr.responseText);
        }
    };

    // XHR callback to process capsule
    BEJSSDK.xhrHandler = function (xhr_event) {
        if (legacyIE) {
            BEJSSDK.processCapsule(xhr.responseText);
        } else {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    BEJSSDK.processCapsule(xhr.responseText);
                } else {
                    addErrorMessage('API request invalid HTTP status=' + xhr.status +
                        ", capsule_url=" + _get_capsule_api_url);
                }
            }
        }
    };

    // XHR error callback
    BEJSSDK.xhrErrorHandler = function (xhr_event) {
        logWarning("Could not get capsule=" + _get_capsule_api_url + ", error=" + xhr_event.statusText);
        addErrorMessage('API request invalid response=' + xhr.statusText +
            ", capsule_url=" + _get_capsule_api_url);
    };

    BEJSSDK.processCapsule = function (capsule_response) {
        _capsule_response = capsule_response;
        // have to put body close fires
        BEJSSDK.connectTime = new Date().getTime() - BEJSSDK.startTime;
        addtoProfileHistory("constructor", BEJSSDK.connectTime);

        try {
            BEJSSDK.capsule = JSON.parse(_capsule_response);
        } catch (err) {
            addErrorMessage("Invalid JSON capsule_url=" + _get_capsule_api_url + ", error_msg=" + err.message);
            return
        }

        if (BEJSSDK.capsule.config.page_groups) {
            // get page_type based on normalized_url and set page_group_nodes for capsule
            var page_group_config = BEJSSDK.capsule.config.page_groups;
            try {
                var page_group = SDKUtils.derivePageGroup(_normalized_url, page_group_config);
                logDebug("page group is " + page_group);
                // set page_group
                BEJSSDK.capsule.page_group = page_group;

                var page_group_nodes = BEJSSDK.getPageGroupNodes(page_group);
                BEJSSDK.setPageGroupNodes(page_group_nodes);
            } catch (err) {
                addErrorMessage("Exception occured while getting page group" + ", error_msg=" + err.message);
                return
            }
        }

        // now drop in modules in targeted place
        var hasBodyOpenNode = false;
        if (BEJSSDK.capsule.page_group_nodes) {
            for (var pg_node_index = 0; pg_node_index < BEJSSDK.capsule.page_group_nodes.length; pg_node_index++) {
                var pg_node = BEJSSDK.capsule.page_group_nodes[pg_node_index];
                var pg_node_type = pg_node.type;
                var pg_feature_group = pg_node.feature_group;
                var overrideNode = false;

                // override node with page_group_node if it exists
                for (var capsule_index = 0; capsule_index < BEJSSDK.capsule.nodes.length; capsule_index++) {
                    var node = BEJSSDK.capsule.nodes[capsule_index];
                    var node_type = node.type;
                    var feature_group = node.feature_group;
                    if (pg_feature_group === feature_group && node_type === pg_node_type) {
                        BEJSSDK.capsule.nodes[capsule_index] = pg_node;
                        overrideNode = true;
                        break;
                    }
                }
                if (!overrideNode) {
                    BEJSSDK.capsule.nodes.push(pg_node);
                }
            }
        }
        for (var capsule_index = 0; capsule_index < BEJSSDK.capsule.nodes.length; capsule_index++) {
            var node = BEJSSDK.capsule.nodes[capsule_index];
            var node_type = node.type;
            var feature_group = node.feature_group;

            // JS sdk uses non_script_content rather than content
            // since JS content needs to be separated out
            var content = "";
            if (node.non_script_content) {
                content = node.non_script_content;
            }
            var script_content = node.script_content;
            var node_publishing_engine = node.publishing_engine;
            var node_engine_version = node.engine_version;
            var node_date_published = node.date_published;
            var meta_string = null;
            if (node.meta_string) {
                meta_string = node.meta_string;
            }
            addErrorMessage("Adding selector index=" + capsule_index + " node=" + node_type + "/" + feature_group);
            if (node_type === 'bodystr' && feature_group === '_body_open') {
                logDebug("Adding selector for index=" + capsule_index + " node=" + node_type + "/" + feature_group);
                BEJSSDKObserver.jsElementReady('body', wrapContentFunction(function updateBody(bodySelector, content, script_content,
                                                                                               node_publishing_engine, node_engine_version, node_type, meta_string) {
                    bodySelector.insertAdjacentHTML('afterbegin', content);
                    if (script_content && script_content.length > 0) {
                        insertNewScript(script_content, bodySelector);
                    }
                    hasBodyOpenNode = true;
                    // show node specific messages in debugMode only
                    if (debugMode) {
                        insertAdornment(bodySelector, true, node_publishing_engine, node_engine_version, node_type, meta_string, node_date_published);
                    }
                    insertBodyClose(bodySelector);
                }, content, script_content, node_publishing_engine, node_engine_version, node_type, meta_string, node_date_published));
            } else if (node_type === 'headstr' && feature_group === '_head_open') {
                logDebug("Adding selector for index=" + capsule_index + " node=" + node_type + "/" + feature_group);
                BEJSSDKObserver.jsElementReady('head', wrapContentFunction(function updateBody(headSelector, content, script_content,
                                                                                               node_publishing_engine, node_engine_version, node_type, meta_string) {
                    var addAdornment = false;
                    var metaAdded = false;
                    var head_meta = getHeadOpenDiagString();

                    if (!legacyIE) {
                        if (content.length > 0) {
                            // post-pend otherwise causes some issues on sites like udemy
                            headSelector.insertAdjacentHTML('beforeend', content);
                            if (!metaAdded) {
                                headSelector.insertAdjacentHTML('beforeend', head_meta);
                                metaAdded = true;
                            }
                            logDebug("Loading head content=" + content + ":");
                            addAdornment = true;
                        }
                    } else {
                        // legacyIE doesn't support changing head's innerhtml
                        // head content is always style
                        var normalizedContent = content.replace('<style>', '');
                        normalizedContent = normalizedContent.replace('</style>', '').trim();
                        if (normalizedContent.length > 0) {
                            var styleSelector = document.createElement('style');
                            styleSelector.innerHTML = normalizedContent + "\n";
                            headSelector.appendChild(styleSelector);
                            addAdornment = true;
                        }
                    }
                    if (script_content && script_content.length > 0) {
                        insertNewScript(script_content, headSelector);
                        var head_meta = getHeadOpenDiagString();
                        if (!metaAdded) {
                            headSelector.insertAdjacentHTML('beforeend', head_meta);
                            metaAdded = true;
                        }
                        logDebug("Loading script_content= " + script_content + ":");
                        addAdornment = true;
                    }
                    if (addAdornment) {
                        insertAdornment(headSelector, false, node_publishing_engine, node_engine_version, node_type, meta_string,
                            node_date_published);
                    }
                }, content, script_content, node_publishing_engine, node_engine_version, node_type, meta_string, node_date_published));
            } else {
                logDebug("Skipping non-implict and unspecified explict selector for index=" + capsule_index + " node=" + node_type + "/" + feature_group);
            }
        }
        // if body open node is not generated force body close call
        if (!hasBodyOpenNode) {
            BEJSSDKObserver.jsElementReady('body', wrapContentFunction(function updateBody(bodySelector) {
                insertBodyClose(bodySelector);
            }));
        }
    };


    BEJSSDK.getNodes = function () {
        return BEJSSDK.capsule.nodes;
    };

    BEJSSDK.getPageGroupNodes = function (pageGroup) {
        return BEJSSDK.capsule.page_group_nodes[pageGroup];
    };

    BEJSSDK.setPageGroupNodes = function (pgNodesArray) {
        BEJSSDK.capsule.page_group_nodes = pgNodesArray;
    };

    BEJSSDK.getPageGroupNodesConfig = function () {
        return BEJSSDK.capsule.config.page_group_nodes;
    };

    // Private Methods
    function logInfo(msg, msg_log_level) {
        return log(msg, BEJSSDK.LOG_LEVEL_INFO);
    }

    function logDebug(msg, msg_log_level) {
        return log(msg, BEJSSDK.LOG_LEVEL_DEBUG);
    }

    function logWarning(msg, msg_log_level) {
        return log(msg, BEJSSDK.LOG_LEVEL_WARNING);
    }

    function log(msg, msg_log_level) {
        if (msg_log_level < logLevel) {
            return;
        }

        forceLog(msg);
    }

    function forceLog(msg) {
        if (typeof window.console !== "undefined") {
            console.log(msg);
        } else {
            if (environment !== BEJSSDK.ENVIRONMENT_PRODUCTION) {
                alert(msg);
            }
        }
    }

    /**
     * Insert a brand new script
     * (use this to work around innerHTML not executing
     */
    function insertNewScript(script_content, target_selector) {
        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.textContent = script_content;

        // insert script tag so it executes
        target_selector.appendChild(s);
    }

    function getHeadOpenDiagString() {
        var sb = "\n<!-- be_ixf, sdk, gho-->";
        var pageHideOriginalUrl = false;
        if (BEJSSDK.PAGE_HIDE_ORIGINALURL && !debugMode) {
            pageHideOriginalUrl = SDKUtils.getBooleanValue(BEJSSDK.PAGE_HIDE_ORIGINALURL);
        }
        sb += "\n<meta name=\"be:sdk\" content=\"" + BEJSSDK.CLIENT_NAME + "_" + BEJSSDK.CLIENT_VERSION + "\" />";
        sb += "\n<meta name=\"be:timer\" content=\"" + BEJSSDK.connectTime + "ms\" />";
        if (!pageHideOriginalUrl) {
            sb += "\n<meta name=\"be:orig_url\" content=\"" + htmlEntities(_original_url) + "\" />";
        }
        sb += "\n<meta name=\"be:norm_url\" content=\"" + htmlEntities(_normalized_url) + "\" />";
        sb += "\n<meta name=\"be:capsule_url\" content=\"" + htmlEntities(displayCapsuleUrl) + "\" />";
        if (BEJSSDK.capsule != null) {
            sb += "\n<meta name=\"be:api_dt\" content=\"" + SDKUtils.convertToNormalizedGoogleIndexTimeZone(BEJSSDK.capsule.date_created, "p") + "\" />";
            sb += "\n<meta name=\"be:mod_dt\" content=\"" + SDKUtils.convertToNormalizedGoogleIndexTimeZone(BEJSSDK.capsule.date_created, "p") + "\" />";
        }
        var hasErrorMessages = errorMessages.length > 0 ? "true" : "false";
        sb += "\n<meta name=\"be:messages\" content=\"" + hasErrorMessages + "\" />";
        return sb;
    }

    function htmlEntities(str) {
        return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
    }

    function addErrorMessage(msg) {
        errorMessages.push(msg);
    }

    function addtoProfileHistory(item, elapsedTime) {
        profileHistory.push([item, elapsedTime]);
    }

    function insertAdornment(element, is_body_open, node_publishing_engine, node_engine_version, node_type, meta_string,
                             date_published) {
        if (is_body_open) {
            // add body open comments
            var openComment = document.createComment("be_ixf, bodystr, _body_opens");
            element.appendChild(openComment);
            var capsule_ul = document.createElement('ul');
            capsule_ul.setAttribute('id', 'be_sdkms_capsule');
            capsule_ul.setAttribute('style', 'display:none!important');

            var capsule_index_time_li = document.createElement('li');
            capsule_index_time_li.setAttribute('id', 'be_sdkms_capsule_index_time');
            capsule_index_time_li.innerHTML = SDKUtils.convertToNormalizedGoogleIndexTimeZone(new Date().getTime(), "i");
            capsule_ul.appendChild(capsule_index_time_li);

            if (BEJSSDK.capsule != null) {
                var publishingEngine = BEJSSDK.capsule.publishing_engine;
                var capsuleVersion = BEJSSDK.capsule.capsule_version;
                var capsuleLine = publishingEngine + "; " + publishingEngine + "_" + capsuleVersion;

                var capsule_publisher_li = document.createElement('li');
                capsule_publisher_li.setAttribute('id', 'be_sdkms_capsule_pub');
                capsule_publisher_li.innerHTML = capsuleLine;
                capsule_ul.appendChild(capsule_publisher_li);

                var capsule_modified_li = document.createElement('li');
                capsule_modified_li.setAttribute('id', 'be_sdkms_capsule_date_modified');
                capsule_modified_li.innerHTML = SDKUtils.convertToNormalizedGoogleIndexTimeZone(BEJSSDK.capsule.date_published, "p");
                capsule_ul.appendChild(capsule_modified_li);
            }
            // insert as first thing
            element.insertBefore(capsule_ul, element.firstChild);
        }

        // body string is html
        // otherwise it is comments
        var nodePublisherLine = node_publishing_engine + "; " + node_publishing_engine + "_" + node_engine_version + "; " + node_type;
        if (meta_string != null) {
            nodePublisherLine = nodePublisherLine + "; " + meta_string;
        }
        var nodePublishedDate = SDKUtils.convertToNormalizedTimeZone(date_published, "p");
        if (node_type === 'bodystr') {
            if (debugMode) {
                var node_ul = document.createElement('ul');
                node_ul.setAttribute('id', 'be_sdkms_node');
                node_ul.setAttribute('style', 'display:none!important');

                var node_publisher_li = document.createElement('li');
                node_publisher_li.setAttribute('class', 'be_sdkms_pub');
                node_publisher_li.innerHTML = nodePublisherLine;
                node_ul.appendChild(node_publisher_li);

                var node_modified_li = document.createElement('li');
                node_modified_li.setAttribute('class', 'be_sdkms_date_modified');
                node_modified_li.innerHTML = nodePublishedDate;
                node_ul.appendChild(node_modified_li);

                element.appendChild(node_ul);
            }
            const bodyComment = document.createComment("be_ixf, bodystr");
            element.appendChild(bodyComment);
        } else {
            if (!legacyIE) {
                var appendedString = "";
                appendedString = appendedString + "<script>\n";
                appendedString = appendedString + "/*\n";
                appendedString = appendedString + "   be_sdkms_pub: " + nodePublisherLine + "\n";
                appendedString = appendedString + "   be_sdkms_date_modified: " + nodePublishedDate + "\n";
                appendedString = appendedString + "*/\n";
                appendedString = appendedString + "</script>\n";
                element.insertAdjacentHTML('beforeend', appendedString);
            }
        }
    }

    // insert the body close section at the end of body
    function insertBodyClose(bodySelector) {
        if (debugMode) {
            // body close debug info
            var closeComment = document.createComment("be_ixf, sdk, is");
            bodySelector.appendChild(closeComment);

            var capsule_ul = document.createElement('ul');
            capsule_ul.setAttribute('id', 'be_sdkms_capsule');
            capsule_ul.setAttribute('style', 'display:none!important');
            // move out capsule messages
            if (errorMessages.length > 0) {
                var capsule_message_ul = document.createElement('ul');
                capsule_message_ul.setAttribute('id', 'be_sdkms_capsule_messages');
                for (var error_index = 0; error_index < errorMessages.length; error_index++) {
                    var errorMessage = errorMessages[error_index];
                    capsule_message_ul.innerHTML = capsule_message_ul.innerHTML + "<!-- ixf_msg: " + errorMessage + " -->\n";
                }
                capsule_ul.appendChild(capsule_message_ul);
            }

            var capsule_config_li = document.createElement('li');
            capsule_config_li.setAttribute('id', 'be_sdkms_configuration');
            capsule_config_li.innerHTML = JSON.stringify(config);
            capsule_ul.appendChild(capsule_config_li);

            var capsule_page_group_li = document.createElement('li');
            capsule_page_group_li.setAttribute('id', 'be_sdkms_page_group');
            if (BEJSSDK.capsule.page_group) {
                capsule_page_group_li.innerHTML = JSON.stringify(BEJSSDK.capsule.page_group);
            }
            capsule_ul.appendChild(capsule_page_group_li);

            // chrome complains about <script> in cdata and //
            var capsule_response_li = document.createElement('li');
            capsule_response_li.setAttribute('id', 'be_sdkms_capsule_response');
            var normalized_capsule_response = _capsule_response.replace("<script>", "&lt;script&gt;");
            capsule_response_li.innerHTML = "<![CDATA[\n" + normalized_capsule_response + "\n]]>\n";
            capsule_ul.appendChild(capsule_response_li);

            var capsule_profile_li = document.createElement('li');
            capsule_profile_li.setAttribute('id', 'be_sdkms_capsule_profile');

            for (var profile_index = 0; profile_index < profileHistory.length; profile_index++) {
                var profileItem = profileHistory[profile_index];
                var profile_item_li = document.createElement('li');
                profile_item_li.setAttribute('id', profileItem[0]);
                profile_item_li.setAttribute('time', profileItem[1]);
                capsule_profile_li.appendChild(profile_item_li);
            }
            capsule_ul.appendChild(capsule_profile_li);
            bodySelector.appendChild(capsule_ul);
        }
    }

    function wrapContentFunction(fn) {
        var retained_arguments_array = Array.prototype.slice.call(arguments, 1);
        return function (element) {
            var new_args_array = [element].concat(retained_arguments_array);
            return fn.apply(this, new_args_array);
        };
    }

    // custom SDK activate
    if (process.env.CUSTOM) {
        let sdkConfig = {};
        if (process.env.SDK_ACCOUNT_ID) {
            sdkConfig[BEJSSDK.ACCOUNT_ID_CONFIG] = process.env.SDK_ACCOUNT_ID;
        }

        if (process.env.SDK_WHITELIST) {
            sdkConfig[BEJSSDK.WHITELIST_PARAMETER_LIST_CONFIG] = process.env.SDK_WHITELIST;
        }

        if (process.env.SDK_CANONICAL_PROTOCOL) {
            sdkConfig[BEJSSDK.CANONICAL_PROTOCOL_CONFIG] = process.env.SDK_CANONICAL_PROTOCOL;
        }

        if (process.env.SDK_CANONICAL_HOST) {
            sdkConfig[BEJSSDK.CANONICAL_HOST_CONFIG] = process.env.SDK_CANONICAL_HOST;
        }

        if (process.env.SDK_LOG_LEVEL) {
            sdkConfig[BEJSSDK.LOG_LEVEL_CONFIG] = process.env.SDK_LOG_LEVEL;
        }

        if (process.env.SDK_REQUEST_PARAMETERS_CASE_SENSITIVE) {
            sdkConfig[BEJSSDK.REQUESTPARAMETERS_CASEINSENSITIVE_CONFIG] = process.env.SDK_REQUEST_PARAMETERS_CASE_SENSITIVE;
        }

        window.BEJSSDK.construct(sdkConfig);
    }
}(window.BEJSSDK = window.BEJSSDK || {}));
